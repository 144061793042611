import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "change-enterprise py-6" }
const _hoisted_3 = {
  key: 1,
  class: "change-enterprise-container"
}
const _hoisted_4 = { class: "change-enterprise-card pa-6" }
const _hoisted_5 = { class: "subtitle-1 mb-4" }
const _hoisted_6 = {
  key: 0,
  class: "change-enterprise-list"
}
const _hoisted_7 = {
  key: 1,
  class: "text-body-2 mb-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_app_spinner, {
            key: 0,
            loading: _ctx.isLoading,
            cover: ""
          }, null, 8, ["loading"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t("title.changeEnterprise")), 1),
              _createVNode(_component_app_text_field, {
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                placeholder: _ctx.t("form.placeholder.findEnterprise"),
                iconPrepend: "",
                iconAppend: "",
                iconAppendAction: () => _ctx.search = "",
                styled: "lined"
              }, _createSlots({
                iconPrepend: _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    icon: "search",
                    size: 16
                  })
                ]),
                _: 2
              }, [
                (_ctx.search.length)
                  ? {
                      name: "iconAppend",
                      fn: _withCtx(() => [
                        _createVNode(_component_app_icon, {
                          icon: "cross",
                          size: 14,
                          class: "text-red"
                        })
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["modelValue", "placeholder", "iconAppendAction"]),
              (_ctx.foundEnterprise.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.foundEnterprise, (item, i) => {
                      return (_openBlock(), _createBlock(_component_app_button, {
                        class: _normalizeClass(["change-enterprise-list-item mt-1 mr-1", item.id == _ctx.state.globals.enterpriseId ? "text-green" : "text-blue"]),
                        key: i,
                        onClick: ($event: any) => (_ctx.enterpriseSelected(item.id)),
                        disabled: item.id == _ctx.state.globals.enterpriseId
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "class", "disabled"]))
                    }), 128))
                  ]))
                : (_ctx.foundEnterprise.length === 0 && _ctx.search.length)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.t("form.notify.noResultsFound")), 1))
                  : _createCommentVNode("", true)
            ])
          ]))
    ])
  ]))
}