
import {defineComponent, ref, computed, onMounted} from 'vue'
import {useI18n} from 'vue-i18n';
import {useStore} from "vuex";
import {Enterprises} from "@/models";
import {useRouter, useRoute} from "vue-router";
import {setCookie} from '@/helpers/cookie';

export default defineComponent ({
	setup() {
		const { t } = useI18n();
		const { state, commit, dispatch } = useStore();

		const enterprises = computed(() => state.dictionaries.enterprises as Enterprises.Enterprise[]);

		const isLoading = ref(false);
		const load = async () => {
			isLoading.value = true;

			try {
				await dispatch('dictionaries/getEnterprises');
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		};

		onMounted(() => load());

		const search = ref<string>("");
		const foundEnterprise = computed(() =>
			enterprises.value.filter((item: any) =>
				item.name.toLowerCase().includes(search.value.toLowerCase())));

		const router = useRouter();
		const route = useRoute();
		const enterpriseSelected = (id: number) => {
			commit('globals/setEnterpriseId', id);
			setCookie("enterpriseId", id);

			const name: any = route.redirectedFrom?.name ?? "dashboard";
			return router.push({name});
		}

		return {
			t,
			state,
			isLoading,
			search,
			foundEnterprise,
			enterpriseSelected
		}
	}
})
